<template>
    <div>
        <ts-page-title
            :title="$t('notificationTemplate.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('notificationTemplate.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div>
                        <a-button
                            type="primary"
                            v-if="$can('create-notification-templates')"
                            @click="addNew"
                        >
                            {{ $t("addNew") }}
                        </a-button>
                    </div>
                    <div class="tw-space-x-3">
                        <a-tooltip :title="$t('notificationTemplate.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('notificationTemplate.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.not_type }}</td>
                        <td>{{ record.not_subject }}</td>
                        <td class="text-left tw-space-x-3 tw-whitespace-nowrap">
                            <a
                                v-if="$can('update-notification-templates')"
                                href="#"
                                v-tooltip="$t('edit')"
                                class="text-primary mr-2"
                                @click.prevent="edit(record)"
                            >
                                <i class="fas fa-edit"></i>
                            </a>
                            <a-popconfirm
                                v-if="$can('delete-notification-templates')"
                                placement="leftTop"
                                :title="$t('are_you_sure_to_delete')"
                                :ok-text="$t('yes')"
                                :cancel-text="$t('no')"
                                @confirm="deleteRecord(record)"
                            >
                                <a href="#" :disabled="record._deleting">
                                    <i
                                        class="fas fa-circle-notch spin"
                                        v-if="record._deleting"
                                    ></i>
                                    <i class="fa fa-trash text-red" v-else></i>
                                </a>
                            </a-popconfirm>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
    name: "notificationTemplate",
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState("notification/notificationTemplate", [
            "resources",
            "pagination"
        ]),
        search: {
            get() {
                return this.$store.state.driver.driverProfile.search;
            },
            set(newValue) {
                this.$store.commit(
                    "notification/notificationTemplate/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "notification/notificationTemplate/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                { name: this.$t("notificationTemplate.notificationType") },
                { name: this.$t("notificationTemplate.subject") },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        addNew() {
            this.$router.push({ name: "notification-template-create" });
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("notification/notificationTemplate/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        edit(record) {
            this.$store.commit(
                "notification/notificationTemplate/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "notification-template-edit",
                params: { id: record.not_template_id }
            });
        },
        deleteRecord(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "notification/notificationTemplate/destroy",
                    record.not_template_id
                )
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.fetchData();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    record._deleting = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("notification/notificationTemplate/RESET_STATE");
        next();
    }
};
</script>
